.Reasons {
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

.leftSideReason {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}

.leftSideReason>img {
  object-fit: cover;
  border-radius: 12px;
}

.leftSideReason>:nth-child(1) {
    width: 14rem;
    height: 28.3rem;
    grid-row: 1/3;
}

.leftSideReason>:nth-child(2) {
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}

.leftSideReason>:nth-child(3) {
    width: 14rem;
    grid-column: 2/3;
}

.leftSideReason>:nth-child(4) {
    width: 10rem;
    grid-column: 3/4;
    grid-row: 2;
}

.rightSideReason {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
}

.rightSideReason>span {
    color: var(--orange);
    font-weight: bold;
    margin-top: 1rem;
}


.rightSideReason>div {
    font-size: 3rem;
    color: #ffffff;
    font-weight: bold;
}

.detailRight {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.detailRight>div {
    display: flex;
    font-size: 1rem;
    gap: 1rem;
    align-items: center;
}

.detailRight>div>img {
    width: 2rem;
    height: 2rem;
}

.ourPartner {
    color: var(--orange);
    font-weight: bold;
    font-size: 1.4rem;
    margin-top: 12px;
}

.partners>img {
    width: 2.5rem;
}

@media only screen and (max-width: 768px){
    .Reasons {
        flex-direction: column;
    }
    .leftSideReason {
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .leftSideReason>:nth-child(1) {
        width: 7rem;
        height: 17rem;
    }
    .leftSideReason>:nth-child(2) {
        width: 15rem;
        height: 10rem;
    }
    .leftSideReason>:nth-child(3) {
        width: 7rem;
        height: 6rem;
    }
    .leftSideReason>:nth-child(4) {
        width: 6rem;
        height: 6rem;
    }
    .rightSideReason>div {
        font-size: xx-large;
    }
}
