.hero {
    display: flex;
    justify-content: space-between;
}

.leftSide {
    padding: 2rem;
    padding-top: 1.6rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.rightSide {
    position: relative;
    flex: 1;
    background-color: var(--orange);
    border-bottom-left-radius: 12px;
}

.theBestAd {
    margin-top: 3rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 14px;
    text-transform: uppercase;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.theBestAd>span {
    z-index: 2;
}

.theBestAd>div {
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.heroText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: #ffffff;
    text-overflow: inherit;
}

.heroText>div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.figures {
    display: flex;
    gap: 2rem;
}

.figures>div {
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1) {
    font-size: 2rem;
    color: #ffffff;
}

.figures>div>span:nth-of-type(2) {
    color: var(--gray);
    text-transform: uppercase;
    font-size: 14px;
}

.heroButtons {
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.heroButtons>:nth-of-type(1) {
    color: #ffffff;
    background-color: var(--orange);
    width: 8rem;
}

.heroButtons>:nth-of-type(2) {
    color: #ffffff;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}

.rightSide>.heroButton {
    position: absolute;
    top: 2rem;
    right: 3rem;
    color: #000000;
}

.heartRate>img {
    width: 2rem;
}

.heartRate {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 3rem;
    top: 7rem;
}

.heartRate>:nth-of-type(1) {
    color: var(--gray);
}

.heartRate>:nth-of-type(2) {
    color: #ffffff;
    font-size: 1.5rem;
}

.heroImage {
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
}

.heroImageBack {
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;
}

.calories {
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: 220px;
    position: absolute;
    top: 36rem;
    right: 28rem;
}

.calories>img {
    width: 2.5rem;
}

.calories>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories>div>:nth-of-type(1) {
    color: var(--gray);
}

.calories>div>:nth-of-type(2) {
    color: #ffffff;
    font-size: 1.5rem;
}

.heroBlur {
    width: 22rem;
    height: 30rem;
    left: 4%;
}

@media only screen and (max-width: 768px){
   .hero {
    flex-direction: column;
   }
   .heroBlur {
    width: 14rem;
   }  
   .theBestAd {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
   }
   .heroText {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
   }
   .heroText>div:nth-of-type(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    align-items: center;
   }
   .heroButtons {
    justify-content: center;
   }
   .figures {
    display: flex;
    gap: 4rem;
   }
   .figures>div>span:nth-of-type(1) {
    font-size: large;
   }
  .figures>div>span:nth-of-type(2) {
    font-size: small;
   }
   .rightSide {
    position: relative;
    background: none;
   }
   .heartRate {
    top: 2rem;
    left: 1rem;
   }
   .calories {
    position: relative;
    top: 5rem;
    left: 2rem;
   }
   .calories>img {
    width: 2rem;
   }
   .calories>div>:nth-of-type(2) {
    color: #ffffff;
    font-size: 1rem;
   }
   .heroImage {
    position: relative;
    width: 15rem;
    left: 9rem;
    top: 4rem;
    align-self: center;
   }
   .heroImageBack {
    width: 15rem;
    left: 2rem;
    top: 0rem;
   }
}


