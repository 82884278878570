.programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programsHeader {
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: #ffffff;
    text-transform: uppercase;
    font-style: italic;
}

.programsCategories {
    display: flex;
    gap: 1rem;
}

.category {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: gray;
    gap: 1rem;
    padding: 2rem;
    color: #ffffff;
    border-radius: 12px;
}

.category>:nth-child(1) {
    width: 2rem;
    height: 2rem;
    fill: #ffffff;
}

.category>:nth-child(2) {
   font-size: 1rem;
   font-weight: bold;
}

.category>:nth-child(3) {
    font-size: 0.9rem;
    line-height: 25px;
 }

 .joinNow {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-transform: uppercase;
    font-size: 14px;
 }

 .joinNow>img {
   width: 1rem;
 }
 
 .category:hover {
   background: var(--planCard);
   cursor: pointer;
   transition: .4s all ease-in-out;
 } 

 @media only screen and (max-width: 768px){
    .programsHeader {
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .programsCategories {
        flex-direction: column;
    }
 }


 