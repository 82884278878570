.testimonials {
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
    margin-bottom: 2rem;
}

.leftTestimonials {
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 0.8rem;
}

.leftTestimonials>:nth-child(1) {
    color: var(--orange);
    font-weight: bold;
}

.leftTestimonials>:nth-child(2),
.leftTestimonials>:nth-child(3) {
    font-size: 3rem;
    font-weight: bold;
}

.leftTestimonials>:nth-child(4) {
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: justify;
    line-height: 30px;
    font-size: 14px;
}

.rightTestimonials {
    position: relative;
    flex: 1;
}

.rightTestimonials>img {
    position: absolute;
    width: 17rem;
    height: 20rem;
    top: 2rem;
    right: 8rem;
    object-fit: cover;
    border-radius: 12px;
}

.rightTestimonials>:nth-child(1) {
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
    border-radius: 10px;
}

.rightTestimonials>:nth-child(2) {
    position: absolute;
    width: 17rem;
    height: 19rem;
    background: var(--planCard);
    right: 7rem;
    top: 4rem;
    border-radius: 10px;
}

.arrows {
    display: flex;
    gap: 1rem;
    left: 5rem;
    bottom: 1rem;
    position: absolute;
}

.arrows>img {
    width: 1.5rem;
    cursor: pointer;
}

@media only screen and (max-width: 768px){
    .testimonials {
        flex-direction: column;
    }
    .leftTestimonials>:nth-child(2),
    .leftTestimonials>:nth-child(3) {
        font-size: xx-large;
   }
   .rightTestimonials {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
   }
   .rightTestimonials>div {
      position: relative;
      display: none;
   }
   .rightTestimonials>img {
      position: relative;
      top: 0;
      right: 0;
   }
   .rightTestimonials>:last-child {
      display: flex;
      left: 0;
      bottom: 0;
      gap: 1rem;
   }
}