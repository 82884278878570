.footerConatiner {
    position: relative;
}

.footerConatiner>hr {
    border: 2px solid var(--orange);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 16rem;
}

.socialLinks {
    display: flex;
    gap: 4rem;
}

.socialLinks>a>img {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}

.footerLogo>img {
    width: 10rem;
}

.footerBlurOne {
    width: 24rem;
    height: 10rem;
    right: 15%;
    bottom: 0%;
    filter: blur(200px);
    background: red;
}

.footerBlurTwo {
    width: 24rem;
    height: 10rem;
    left: 15%;
    bottom: 0%;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}